.featuresRow {
    margin-top: 60px;
}
.item {
    margin-bottom: 50px;
}
.item:nth-last-child(-n+3) {
    margin-bottom: 0;
}
@media screen and (max-width: 768px) {
    .item:nth-last-child(-n+3) {
        margin-bottom: 50px;
    }
    .item:nth-last-child(-n+2) {
        margin-bottom: 0;
    }
}
@media screen and (max-width: 575px) {
    .item {
        margin-bottom: 40px;
    }
    .item:nth-last-child(-n+2) {
        margin-bottom: 40px;
    }
    .item:nth-last-child(1) {
        margin-bottom: 0;
    }

}