.features  h1 {
    max-width: 790px;
}
.featuresRow {
    margin-top: 60px;
}
@media screen and (max-width: 575px) {

    .title {
        text-align: center !important;
    }
}
@media screen and (max-width: 450px) {
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .title {
        padding-right: 20px;
    }
    .features  div[class*="Oval"] {
        width: 48px !important;
        height: 48px !important;
    }

}