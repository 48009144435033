.logos div[class*="col-auto"] {
    padding: 0;
}
.logos > div[class*="row"] {
    justify-content: space-between;
    margin: 0;
    width: 100%;
}
.dark.logos svg path {
    fill: #ffffff;
}
@media screen and (max-width: 991px) {
    .logos div[class*="col-auto"] {
        margin-bottom: 20px;
        margin-right: 30px;
    }
    .logos div[class*="col-auto"]:nth-of-type(3),
    .logos div[class*="col-auto"]:nth-last-of-type(1) {
        margin-right: 0;
    }
    .logos div[class*="col-auto"]:nth-last-of-type(1) {
        margin-right: auto;
    }
}

@media screen and (max-width: 768px) {

    .logos div[class*="col-auto"] svg {
        width: 80%;
    }
    .logos div[class*="col-auto"] {
        max-width: 33.3%;
        min-width: 30%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
