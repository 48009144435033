
.TextButton,
.TextButton:hover {
    display: inline-block;
    font-family: "Inter", "Arial", sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: -0.4px !important;

    color: #18214D !important;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.TextButton:hover {
  opacity: 0.7;
}
.TextButton svg {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.TextButton .text {
    display: inline-block;
    margin: 0;

    font-family: "Inter", "Arial", sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: -0.4px !important;

    color: #18214D !important;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.TextButton:hover .text {
    color: #2A3A87 !important;
}
.TextButton:hover svg {
    opacity: 1;
}
.dark .TextButton .text {
    color: #ffffff !important;
}

.dark .TextButton:hover .text {
    color: #D8D8D8!important;
}

@media screen and (max-width: 450px) {
    .TextButton .text {
        font-size: 13px !important;
        line-height: 16px !important;
        letter-spacing: -0.3px !important;
    }
}
