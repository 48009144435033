@media screen and (max-width: 768px) {
    .form div[class*="col"] {
        margin-bottom: 15px !important;
    }
    .form div[class*="col"]:nth-last-of-type(1) {
        margin-bottom: 0 !important;
    }
    .form div[class*="col-auto"] {
        width: 100%;
    }
}