.PlayButton {
    display: block;
}
.PlayButton.large {
    width: 68px;
    height: 68px;
}
.PlayButton.large svg {
    width: 68px;
    height: 68px;
}
.PlayButton.normal {
    width: 48px;
    height: 48px;
}
.PlayButton.round {
    width: auto !important;
    height: auto !important;
}
.PlayButton {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.PlayButton:hover {
    opacity: 0.8;
}

.PlayButton:hover svg {
  opacity: 1 !important;
}
